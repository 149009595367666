










import { computed, defineComponent } from '@vue/composition-api'
import { mapGetters } from '@/store/utils'
import { Pages } from '@/inc/types'
import DefaultTemplate from './DefaultTemplate.vue'
import Archive from './Archive.vue'

const Default = {
  template:
    '<div><h1>Default page</h1><p>Default…(no subpage component)</p></div>',
}

const pages: Pages = {
  // eslint-disable-next-line quote-props
  page: DefaultTemplate,
  'archive-article': Archive,
}

export default defineComponent({
  name: 'VPage',
  setup(props, ctx) {
    const { template } = mapGetters(ctx, ['template'])
    const slug = computed(() => template.value)
    const component = computed(
      () => pages[(slug.value as unknown) as string] || Default
    )

    return {
      component,
    }
  },
})
